import { axiosWithAuth } from "@app/common/plugins/axios.js";

/**
 * @typedef AssetListItem
 * @type {object}
 * @property {number} id
 * @property {string} name
 * @property {number} size
 * @property {string} extension
 * @property {Object} image
 * @property {string} download
 * @property {ChannelDownload[]} channelDownloads
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @typedef ChannelDownload
 * @type {object}
 * @property {string} channel_code
 * @property {string} channel_name
 * @property {string} url
 */

/**
 * @typedef Pagination
 * @type {object}
 * @property {number} items
 * @property {number} total
 * @property {number} pages
 */

/**
 * @param page
 * @param limit
 * @param filter
 * @returns {Promise<{ pagination: Pagination, data: AssetListItem }>}
 */
export function searchAssets(page, limit, filter = {}) {
  return axiosWithAuth
    .get("/api/v1/modules/assets/", {
      params: {
        page,
        limit,
        ...filter,
      },
    })
    .then((response) => response.data);
}

export function getCategories() {
  return axiosWithAuth
    .get("/api/v1/modules/categories/tree")
    .then((response) => response.data.data);
}
