import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const useBasketStore = defineStore("basket", {
  state: () => {
    return {
      items: useStorage("basketItems", []),
      selectedChannel: "",
    };
  },

  actions: {
    addToBasket(assetId) {
      this.items.push({
        id: assetId,
      });
    },
    removeFromBasket(assetId) {
      this.items = this.items.filter((item) => item.id !== assetId);
    },
    clearBasket() {
      this.items = [];
    },
    resetBasket() {
      this.items = [];
      this.selectedChannel = "";
    },
  },

  getters: {
    amountInBasket: (state) => state.items.length,
    isInBasket: (state) => {
      return (assetId) => !!state.items.find((item) => item.id === assetId);
    },
    basketIsEmpty: (state) => state.items.length === 0,
    basketIds: (state) => state.items.map((x) => x.id),
  },
});
