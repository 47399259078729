<template>
  <div class="app__header__actions">
    <ul class="nav app__header__actions__nav">
      <!-- Basket -->
      <li class="nav-item app__header__actions__nav__item">
        <span class="app__header__actions__nav__basket">
          <router-link
            class="nav-link app__header__actions__nav__link portal__button__light portal__button__with__icon app__header__basket__trigger__button"
            aria-current="page"
            :to="{ name: 'basket' }"
          >
            <span
              class="badge bg-primary app__header__actions__nav__basket__counter"
            >
              {{ basketStore.amountInBasket }}
            </span>
            <span
              class="material-icons-outlined app__header__basket__trigger__icon"
            >
              shopping_cart
            </span>
          </router-link>
        </span>
      </li>

      <!-- Language Selection -->
      <li class="nav-item app__header__actions__nav__item">
        <span class="nav-link app__header__actions__nav__link">
          <app-layout-language-selector />
        </span>
      </li>

      <!-- Logout -->
      <li class="nav-item app__header__actions__nav__item">
        <span class="nav-link app__header__actions__nav__link">
          <app-layout-logout-dialog />
        </span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { useBasketStore } from "@app/common/stores/basketStore.js";
import AppLayoutLanguageSelector from "@app/layouts/app/AppLayoutLanguageSelector.vue";
import AppLayoutLogoutDialog from "@app/layouts/app/AppLayoutLogoutDialog.vue";

const basketStore = useBasketStore();
</script>
