import { axiosWithAuth } from "@app/common/plugins/axios.js";

/**
 * @typedef CollectionItem
 * @type {object}
 * @property {number} id
 * @property {string} name
 * @property {string} downloadLink
 */

/**
 *
 * @param brand
 * @returns {Promise<CollectionItem[]>}
 */
export function searchCollections(brand = undefined) {
  // TODO: Implement paging?
  return axiosWithAuth
    .get("/api/v1/modules/publications/", {
      params: {
        brand,
        limit: 10000,
      },
    })
    .then((result) => result.data.data);
}
