import { axiosWithAuth } from "@app/common/plugins/axios.js";

/**
 * @typedef DashboardData
 * @type {object}
 * @property {string} heading
 * @property {string} subheading
 * @property {DashboardContact} contact
 * @property {DashboardHighlight[]} highlights
 * @property {boolean} [highlightsNoAccordion]
 */

/**
 * @typedef DashboardContact
 * @type {object}
 * @property {string} image
 * @property {string} name
 * @property {string} email
 */

/**
 * @typedef DashboardHighlight
 * @type {object}
 * @property {string} title
 * @property {DashboardHighlightItem[]} subheading
 */

/**
 * @typedef DashboardHighlightItem
 * @type {object}
 * @property {string} heading
 * @property {string} subheading
 * @property {string} background
 * @property {string} link
 * @property {boolean} openInNewTab
 */

/**
 * @returns {Promise<DashboardData>}
 */
export function getDashboardData() {
  return axiosWithAuth
    .get("/api/v1/modules/dashboard/data")
    .then((response) => response.data.data);
}
