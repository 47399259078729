export default {
  languageSelector: {
    buttonText: "Sprache",
    dialogTitle: "Sprache wechseln",
  },
  footer: {
    imprint: "Impressum",
    privacyPolicy: "Datenschutz",
  },
};
