import AssetListPage from "@app/modules/asset-list/pages/asset-list/AssetListPage.vue";
import BasketPage from "@app/modules/basket/pages/basket/BasketPage.vue";
import CollectionsPage from "@app/modules/collections/pages/collections/CollectionsPage.vue";
import DashboardPage from "@app/modules/dashboard/pages/dashboard/DashboardPage.vue";
import LoginPage from "@app/modules/auth/pages/login/LoginPage.vue";
import PasswordResetPage from "@app/modules/auth/pages/password-reset/PasswordResetPage.vue";
import RegistrationPage from "@app/modules/auth/pages/registration/RegistrationPage.vue";
import NewPasswordPage from "@app/modules/auth/pages/password-reset/NewPasswordPage.vue";

const routes = [
  // auth
  {
    path: "/auth/login",
    name: "login",
    component: LoginPage,
    meta: { auth: false },
  },
  {
    path: "/auth/registration",
    name: "registration",
    component: RegistrationPage,
    meta: { auth: false },
  },
  {
    path: "/auth/reset-password",
    name: "reset-password",
    component: PasswordResetPage,
    meta: { auth: false },
  },
  {
    path: "/auth/new-password",
    name: "new-password",
    component: NewPasswordPage,
    meta: { auth: false },
  },
  // dashboard
  {
    path: "/",
    name: "dashboard",
    component: DashboardPage,
    meta: { auth: true },
  },
  // assets
  {
    path: "/assets",
    name: "asset-list",
    component: AssetListPage,
    meta: { auth: true },
  },
  // collections
  {
    path: "/collections",
    name: "collections",
    component: CollectionsPage,
    meta: { auth: true },
  },
  // basket
  {
    path: "/basket",
    name: "basket",
    component: BasketPage,
    meta: { auth: true },
  },
];

export default routes;
