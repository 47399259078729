export default {
  login: {
    fields: {
      email: "Email",
      password: "Password",
    },
    actions: {
      forgotPassword: "Forgot password",
      submit: "Login",
      register: "Registration",
    },
  },
  registration: {
    title: "Create new account",
    fields: {
      name: "Name",
      company: "Company",
      department: "Department",
      position: "Position",
      email: "Email",
      comment: "Comment",
    },
    texts: {
      termsV1: "I have read and agree to the",
      termsV2: "privacy policy.",
      termsV3: "",
      userData:
        "I agree that my data will be stored for the purpose of using the press portal.",
    },
    actions: {
      submit: "Create account",
      cancel: "Cancel",
    },
    success: {
      title: "Registration successful",
      text: "Your registration was successful. Once we have activated your account, you will receive a confirmation email.",
      actions: {
        login: "Go to login",
      },
    },
  },
  resetPassword: {
    title: "Reset password",
    text: "TBD: Text",
    fields: {
      email: "Email",
    },
    actions: {
      submit: "Request new password",
      cancel: "Cancel",
    },
  },
  newPassword: {
    title: "Reset password",
    text: "TBD: Text",
    fields: {
      password: "New password",
    },
    actions: {
      submit: "Save new password",
      cancel: "Cancel",
    },
  },
};
