<template>
  <div class="dashboard__header py-5">
    <div class="container">
      <div class="dashboard__header__heading portal__h1">
        {{ banner.heading }}
      </div>
      <div
        v-if="banner.subheading"
        class="dashboard__header__subheading portal__h5"
      >
        {{ banner.subheading }}
      </div>
      <div v-if="banner.button" class="dashboard__header__action mt-3">
        <a
          :href="banner.button.link"
          :target="banner.button.openInNewTab ? '_blank' : '_self'"
          class="dashboard__header__action__link btn btn-primary"
        >
          {{ banner.button.text }}
        </a>
      </div>
    </div>
    <div class="dashboard__header__banner__image" :style="style"></div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  banner: Object,
});

const style = computed(() => {
  return {
    backgroundImage: `url(${props.banner.background})`,
  };
});
</script>

<style lang="scss" src="./DashboardPageHeader.scss" scoped />
