<template>
  <div class="portal__card__default basket__list__item">
    <div class="basket__list__item__image">
      <img :src="item.image.small" />
    </div>
    <div class="portal__h5 basket__list__item__name">
      {{ item.name }}
    </div>
    <div class="basket__list__item__meta">
      {{ item.extension }}
      <!--        Separator -->
      &middot;
      {{ $filters.fileSize(item.fileSize, true) }}
    </div>
    <div class="basket__list__item__actions">
      <span
        class="material-icons-outlined portal__button__icon__dark"
        @click.prevent="basketStore.removeFromBasket(item.id)"
        >delete</span
      >
    </div>
  </div>
</template>

<script setup>
import { useBasketStore } from "@app/common/stores/basketStore";

const basketStore = useBasketStore();

defineProps({
  item: {
    required: true,
    type: Object,
  },
});
</script>
