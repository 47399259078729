import appLayoutModule from "@app/layouts/app/translations/de_DE.js";
import authLayoutModule from "@app/layouts/auth/translations/de_DE.js";

import assetDetailModule from "@app/modules/asset-detail/translations/de_DE.js";
import assetListModule from "@app/modules/asset-list/translations/de_DE.js";
import authModule from "@app/modules/auth/translations/de_DE.js";
import basketModule from "@app/modules/basket/translations/de_DE.js";
import collectionsModule from "@app/modules/collections/translations/de_DE.js";
import dashboardModule from "@app/modules/dashboard/translations/de_DE.js";

export default {
  modules: {
    appLayout: appLayoutModule,
    authLayout: authLayoutModule,

    assetDetail: assetDetailModule,
    assetList: assetListModule,
    auth: authModule,
    basket: basketModule,
    collections: collectionsModule,
    dashboard: dashboardModule,
  },
  languages: {
    de_DE: "Deutsch",
    en_US: "Englisch",
  },
  qualities: {
    original: "Original",
  },
  validations: {
    acceptTermsAndConditions: "Sie müssen die Datenschutzerklärung bestätigen.",
    acceptUserData: "Sie müssen die Speicherung Ihrer Daten bestätigen.",
    fieldRequired: "Ist ein Pflichtfeld.",
    is_null_error: "Ist ein Pflichtfeld.",
    is_blank_error: "Ist ein Pflichtfeld.",
    mustBeValidEmail: "Muss eine gültige E-Mail enthalten.",
    email_invalid_format_error: "Muss eine gültige E-Mail enthalten.",
    invalidCredentials: "Ungültige Zugangsdaten.",
  },
};
