import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const useUserInfoStore = defineStore("userInfo", {
  state: () => {
    return {
      isLoggedIn: false,
      token: useStorage("token", ""),
      user: {
        name: undefined,
      },
    };
  },
  actions: {
    login(authInfo) {
      this.isLoggedIn = true;
      this.user.name = authInfo.user.name;
      if (authInfo.token) {
        this.token = authInfo.token;
      }
    },
    logout() {
      this.isLoggedIn = false;
      this.token = "";
      this.user.name = undefined;
    },
  },

  getters: {
    hasToken: (state) => state.token && state.token !== "",
  },
});
