<template>
  <div class="asset__detail__actions">
    <div v-if="loading" class="placeholder-wave">
      <a
        href="#"
        tabindex="-1"
        class="btn btn-primary disabled placeholder col-6"
      />
      <a
        href="#"
        tabindex="-1"
        class="btn btn-secondary disabled placeholder col-6"
      />
    </div>

    <template v-else>
      <div class="container">
        <div class="portal__buttons__group pt-0">
          <!-- Download Button -->
          <VDropdown
            :distance="6"
            placement="top"
            container=".asset_detail"
            boundary=".asset_detail"
          >
            <button class="portal__button__primary portal__button__with__icon">
              <span class="material-icons-outlined">file_download</span>
              {{ $t("modules.assetDetail.actions.download") }}
            </button>
            <template #popper="{ hide }">
              <div class="portal__context__menu list-group-flush">
                <a
                  :href="asset.download"
                  target="_blank"
                  class="list-group-item portal__button__with__icon justify-content-start"
                  @click="hide()"
                >
                  <span class="material-icons-outlined">file_download</span>
                  {{ $t("qualities.original") }}
                </a>
                <a
                  :href="channelDownload.url"
                  target="_blank"
                  v-for="(channelDownload, index) in asset.channelDownloads"
                  :key="index"
                  class="list-group-item portal__button__with__icon justify-content-start"
                  @click="hide()"
                >
                  <span class="material-icons-outlined">file_download</span>
                  {{ channelDownload.channel_name }}
                </a>
              </div>
            </template>
          </VDropdown>

          <!-- Basket Button -->
          <button
            v-if="!isInBasket"
            @click.prevent="addToBasket()"
            class="portal__button__secondary portal__button__with__icon"
          >
            <span class="material-icons-outlined">add_shopping_cart</span>
            <span>{{ $t("modules.assetDetail.actions.addToBasket") }}</span>
          </button>
          <button
            v-else
            @click.prevent="removeFromBasket()"
            class="portal__button__secondary portal__button__with__icon"
          >
            <span class="material-icons-outlined">remove_shopping_cart</span>
            <span>{{
              $t("modules.assetDetail.actions.removeFromBasket")
            }}</span>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useBasketStore } from "@app/common/stores/basketStore.js";
import { computed } from "vue";

const props = defineProps({
  loading: {
    required: false,
    default: false,
    type: Boolean,
  },
  asset: {
    type: Object,
  },
});

const basketStore = useBasketStore();
const isInBasket = computed(
  () => props.asset && basketStore.isInBasket(props.asset.id)
);

const addToBasket = () => basketStore.addToBasket(props.asset.id);
const removeFromBasket = () => basketStore.removeFromBasket(props.asset.id);
</script>
