<template>
  <div class="portal__form collections__brand__selection">
    <select
      :value="modelValue"
      @input="updateValue"
      class="portal__form__select collections__brand__selection__select"
    >
      <option value="">
        {{ $t("modules.collections.brands.selectBrand") }}
      </option>
      <option
        v-for="brand in appInfoStore.brands"
        :key="brand.code"
        :value="brand.code"
      >
        {{ brand.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfoStore = useAppInfoStore();

defineProps({
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>
