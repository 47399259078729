import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import { useUserInfoStore } from "@app/common/stores/userInfoStore.js";

const router = new createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to) => {
  const userInfo = useUserInfoStore();
  if (to.meta.auth && !userInfo.isLoggedIn) {
    return { name: "login" };
  }
});

export default router;
