<template>
  <div class="asset__list__filter row portal__container__spacing__default">
    <div class="asset__list__filter__cols col-12 col-md-9 col-lg-7 col-xxl-5">
      <!-- Text Search -->
      <div class="portal__form asset__list__filter__search">
        <div
          class="portal__input__wrapper portal__input__group pb-0 asset__list__filter__search__input__group"
        >
          <div
            class="portal__input__group__text rounded-pill bg-light asset__list__filter__search__input__group__text"
          >
            <span class="material-icons-outlined">search</span>
            <input
              type="text"
              class="portal__form__control asset__list__filter__search__input__group__input"
              :placeholder="$t('modules.assetList.textSearch.placeholder')"
              v-model="searchText"
            />
            <span
              v-if="searchText"
              @click.prevent="resetSearchText()"
              class="material-icons-outlined asset__list__filter__search__close__icon"
            >
              close
            </span>
          </div>
        </div>
      </div>

      <!-- Category Dialog -->
      <asset-list-page-category-dialog />

      <!-- Filter Dialog -->
      <asset-list-page-filter-dialog />
    </div>
  </div>

  <!-- Active Filter -->
  <div
    v-if="activeFilters.length > 0"
    class="portal__container__spacing__default asset__list__filter__active"
  >
    <div
      v-for="(activeFilter, index) in activeFilters"
      :key="index"
      class="badge bg-secondary fs-6 asset__list__filter__active__item"
    >
      <template v-if="activeFilter.type === 'typeFilter'">
        <span class="material-icons-outlined">tune</span>
        {{ $t(`modules.assetList.filters.assetType.${activeFilter.value}`) }}
      </template>
      <template v-else-if="activeFilter.type === 'category'">
        <span class="material-icons-outlined">folder</span>
        {{ assetListStore.categoryName(activeFilter.value) }}
      </template>
      <template v-else>
        {{ activeFilter.value }}
      </template>
      <span
        @click="removeActiveFilter(activeFilter)"
        class="material-icons-outlined asset__list__filter__active__item__close"
      >
        close
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, customRef } from "vue";
import AssetListPageCategoryDialog from "@app/modules/asset-list/pages/asset-list/AssetListPageCategoryDialog.vue";
import AssetListPageFilterDialog from "@app/modules/asset-list/pages/asset-list/AssetListPageFilterDialog.vue";
import { useAssetListStore } from "@app/modules/asset-list/store/assetListStore.js";
import { debounce } from "@app/common/helper/debounce.js";

const assetListStore = useAssetListStore();

const activeFilters = computed(() => [
  ...assetListStore.activeFilters.type.map((v) => ({
    value: v,
    type: "typeFilter",
  })),
  ...assetListStore.activeCategories.map((v) => ({
    value: v,
    type: "category",
  })),
]);

const removeActiveFilter = ({ value, type }) => {
  if (type === "typeFilter") {
    assetListStore.removeActiveTypeFilter(value);
  }
  if (type === "category") {
    assetListStore.removeActiveCategory(value);
  }
  assetListStore.triggerNewSearch();
};

const resetSearchText = () => {
  assetListStore.activeSearch = "";
  assetListStore.triggerNewSearch();
};

// Debounced "assetListStore.activeSearch"
const searchText = customRef((track, trigger) => ({
  get() {
    track();
    return assetListStore.activeSearch;
  },
  set: debounce((value) => {
    assetListStore.activeSearch = value;
    assetListStore.triggerNewSearch();
    trigger();
  }, 500),
}));
</script>
