export default {
  languageSelector: {
    buttonText: "Language",
    dialogTitle: "Switch language",
  },
  footer: {
    imprint: "Imprint",
    privacyPolicy: "Privacy policy",
  },
};
