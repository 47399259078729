<template>
  <auth-layout>
    <div
      class="portal__card__default bg-white px-4 py-4 container new__password__dialog"
    >
      <!-- Branding -->
      <auth-branding />

      <!-- Title & Text -->
      <div class="new__password__info">
        <div class="portal__h5 new__password__info__heading">
          {{ $t("modules.auth.newPassword.title") }}
        </div>
        <div class="new__password__info__text">
          {{ $t("modules.auth.newPassword.text") }}
        </div>
      </div>

      <form
        @submit.prevent="doSubmit()"
        class="portal__form new__password__form"
      >
        <!-- Password -->
        <div class="portal__input__wrapper new__password__form__password">
          <input
            v-model="formData.password"
            :placeholder="$t('modules.auth.newPassword.fields.password')"
            id="newPassword"
            type="password"
            class="portal__form__control"
          />
          <label for="newPassword" class="portal__form__label">
            {{ $t("modules.auth.newPassword.fields.password") }}
          </label>
        </div>

        <!-- Reset Passwort Button -->
        <div class="portal__buttons__group">
          <button
            class="portal__button__primary new__password__new__password__button"
            type="submit"
          >
            {{ $t("modules.auth.newPassword.actions.submit") }}
          </button>

          <!-- Cancel Link -->
          <router-link
            :to="{ name: 'login' }"
            class="portal__button__secondary new__password__cancel__button"
          >
            {{ $t("modules.auth.newPassword.actions.cancel") }}
          </router-link>
        </div>
      </form>
    </div>
  </auth-layout>
</template>

<script setup>
import AuthLayout from "@app/layouts/auth/AuthLayout.vue";
import AuthBranding from "@app/modules/auth/components/AuthBranding.vue";
import { reactive, ref } from "vue";

const isProcessing = ref(false);
const formData = reactive({
  password: "",
});

const doSubmit = async () => {
  isProcessing.value = true;
  try {
    // TODO: Success
  } catch {
    // TODO: Error
  }
  isProcessing.value = false;
};
</script>
