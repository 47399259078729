import { axiosWithAuth, axiosWithoutAuth } from "@app/common/plugins/axios.js";

export function login(email, password) {
  return axiosWithoutAuth
    .post("/api/v1/modules/auth/login", {
      email,
      password,
    })
    .then((result) => result.data.data);
}

export function logout() {
  return axiosWithAuth
    .get("/api/v1/modules/auth/logout")
    .then((result) => result.data);
}

export function register(name, company, resort, position, email, comment) {
  return axiosWithoutAuth
    .post("/api/v1/modules/auth/registration", {
      name,
      company,
      resort,
      position,
      email,
      comment,
    })
    .then((result) => result.data.data);
}

export function resetPassword(email) {
  return axiosWithoutAuth.post("/api/v1/modules/auth/reset-password", {
    email,
  });
}
