<template>
  <portal-dialog>
    <!-- Button -->
    <template v-slot:activator="{ open }">
      <button
        class="auth__language__selection__trigger__button"
        @click.prevent="open()"
      >
        <span
          class="material-icons-outlined auth__language__selection__trigger__icon"
        >
          language
        </span>
        {{ $t("modules.authLayout.languageSelector.buttonText") }}
      </button>
    </template>

    <template v-slot="{ close }">
      <!-- Header -->
      <div class="dialog__header">
        <!--  Icon -->
        <div class="dialog__header__icon">
          <span class="material-icons-outlined">language</span>
        </div>

        <!--  Title -->
        <div class="portal__h5 dialog__header__title">
          {{ $t("modules.authLayout.languageSelector.dialogTitle") }}
        </div>

        <!-- Close Button -->
        <div class="dialog__header__close">
          <button
            class="dialog__header__close__button"
            @click.prevent="close()"
          >
            <span class="dialog__header__close__icon material-icons-outlined">
              close
            </span>
          </button>
        </div>
      </div>
      <div class="dialog__body auth__language__selection__dialog">
        <ul class="portal__list__default auth__language__selection__list">
          <li
            class="auth__language__selection__list__item"
            v-for="locale in $i18n.availableLocales"
            :key="locale"
          >
            <button
              class="auth__language__selection__list__item__button"
              @click.prevent="setLocale(locale, close)"
            >
              {{ $t(`languages.${locale}`) }}
            </button>
          </li>
        </ul>
      </div>
    </template>
  </portal-dialog>
</template>

<script setup>
import PortalDialog from "@app/common/components/PortalDialog.vue";
import i18n from "@app/common/plugins/i18n.js";

const setLocale = (locale, close) => {
  i18n.global.locale.value = locale;
  close();
};
</script>
