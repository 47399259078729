<template>
  <portal-dialog ref="dialog" full>
    <template v-slot="{ close }">
      <div class="asset_detail">
        <!-- Header -->
        <asset-detail-dialog-header
          :close="close"
          :loading="isLoading"
          :asset="asset"
        />

        <div class="asset__detail__main">
          <!-- Preview -->
          <asset-detail-dialog-preview
            class="asset__detail__preview"
            :loading="isLoading"
            :asset="asset"
          />

          <!-- Meta -->
          <div class="container">
            <asset-detail-dialog-meta :loading="isLoading" :asset="asset" />
          </div>
        </div>

        <!-- Actions -->
        <asset-detail-dialog-actions :loading="isLoading" :asset="asset" />
      </div>
    </template>
  </portal-dialog>
</template>

<script setup>
import PortalDialog from "@app/common/components/PortalDialog.vue";
import AssetDetailDialogHeader from "@app/modules/asset-detail/dialogs/asset-detail/AssetDetailDialogHeader.vue";
import AssetDetailDialogPreview from "@app/modules/asset-detail/dialogs/asset-detail/AssetDetailDialogPreview.vue";
import AssetDetailDialogMeta from "@app/modules/asset-detail/dialogs/asset-detail/AssetDetailDialogMeta.vue";
import AssetDetailDialogActions from "@app/modules/asset-detail/dialogs/asset-detail/AssetDetailDialogActions.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { getAsset } from "@app/modules/asset-detail/api/assetDetailApi.js";
import eventBus from "@app/common/plugins/eventBus.js";

const dialog = ref();
const isLoading = ref(true);
const asset = ref(null);

const open = async (assetId) => {
  isLoading.value = true;
  dialog.value.open();
  asset.value = await getAsset(assetId);
  isLoading.value = false;
};

const eventListener = (assetId) => open(assetId);
onMounted(() => {
  eventBus.off("portal:asset-dialog:open", eventListener);
  eventBus.on("portal:asset-dialog:open", eventListener);
});
onUnmounted(() => eventBus.off("portal:asset-dialog:open", eventListener));

defineExpose({
  open,
});
</script>
