<template>
  <footer
    class="portal__card__default bg-white px-4 py-2 container portal__auth__footer"
  >
    <ul class="portal__list__default portal__auth__footer__list">
      <li class="portal__auth__footer__list__item">
        <a
          :href="appInfo.links.imprint"
          class="portal__link fs-6 portal__auth__footer__list__item__link"
        >
          {{ $t("modules.authLayout.footer.imprint") }}
        </a>
      </li>
      <li class="app__footer__list__item">
        <a
          :href="appInfo.links.privacyPolicy"
          class="portal__link fs-6 portal__auth__footer__list__item__link"
        >
          {{ $t("modules.authLayout.footer.privacyPolicy") }}
        </a>
      </li>
    </ul>
  </footer>
</template>

<script setup>
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfo = useAppInfoStore();
</script>
