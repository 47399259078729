<template>
  <transition name="fade">
    <button
      class="app__back__to__top__button"
      v-if="isVisible"
      @click.prevent="scrollToTop()"
    >
      <span class="material-icons-outlined">expand_less</span>
    </button>
  </transition>
</template>

<script setup>
import { computed, ref } from "vue";
import { useScroll } from "@vueuse/core";

const { y } = useScroll(ref(window));
const isVisible = computed(() => y.value > 500);

const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};
</script>
