export default {
  navigation: {
    home: "Home",
    assetList: "Medien",
    collections: "Sammlungen",
  },
  languageSelector: {
    dialogTitle: "Sprache wechseln",
  },
  logout: {
    dialogTitle: "Abmelden",
    dialogQuestion: "Wollen Sie sich wirklich abmelden?",
    actions: {
      logout: "Abmelden",
      cancel: "Abbrechen",
    },
  },
  footer: {
    imprint: "Impressum",
    privacyPolicy: "Datenschutz",
  },
};
