<template>
  <div class="py-2 container app__header__navigation">
    <ul class="nav nav-fill app__header__navigation__pills">
      <!-- Dashboard -->
      <li class="nav-item app__header__navigation__item">
        <router-link
          class="nav-link app__header__navigation__link"
          active-class="app__header__navigation__link_active"
          :to="{ name: 'dashboard' }"
        >
          <span class="material-icons-outlined">home</span>
          {{ $t("modules.appLayout.navigation.home") }}
        </router-link>
      </li>

      <!-- Assets -->
      <li class="nav-item app__header__navigation__item">
        <router-link
          class="nav-link app__header__navigation__link"
          active-class="app__header__navigation__link_active"
          :to="{ name: 'asset-list' }"
        >
          <span class="material-icons-outlined">find_in_page</span>
          {{ $t("modules.appLayout.navigation.assetList") }}
        </router-link>
      </li>

      <!-- Collections -->
      <li class="nav-item app__header__navigation__item">
        <router-link
          class="nav-link app__header__navigation__link"
          active-class="app__header__navigation__link_active"
          :to="{ name: 'collections' }"
        >
          <span class="material-icons-outlined">all_inbox</span>
          {{ $t("modules.appLayout.navigation.collections") }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
