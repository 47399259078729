<template>
  <div class="app__header__logo">
    <img :src="appInfoStore.branding.appLogo" />
  </div>
</template>

<script setup>
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfoStore = useAppInfoStore();
</script>
