<template>
  <button
    class="portal__link portal__tagline basket__empty__basket"
    @click.prevent="basketStore.clearBasket"
  >
    Warenkorb leeren
  </button>
</template>

<script setup>
import { useBasketStore } from "@app/common/stores/basketStore";

const basketStore = useBasketStore();
</script>
