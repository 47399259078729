import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "@app/App.vue";
import router from "@app/router";
import i18n from "@app/common/plugins/i18n";
import "@app/app.scss";
import { fileSize } from "@app/common/filters/fileSize.js";
import { dateMed, dateFull } from "@app/common/filters/dateTime.js";
import { vfmPlugin } from "vue-final-modal";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";
import { useUserInfoStore } from "@app/common/stores/userInfoStore.js";
import { getAppInfo } from "@app/api/appInfoApi.js";
import { getAuthInfo } from "@app/api/authInfoApi.js";
import * as Yup from "yup";

// Init app
const pinia = createPinia();

initApp().then(() => {
  const app = createApp(App)
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(vfmPlugin)
    .use(FloatingVue);
  app.config.globalProperties.$filters = { fileSize, dateMed, dateFull };
  app.mount("#app");

  Yup.setLocale({
    mixed: {
      required: "fieldRequired",
    },
    string: {
      email: "mustBeValidEmail",
    },
  });
});

async function initApp() {
  const appInfoStore = useAppInfoStore(pinia);
  const userInfoStore = useUserInfoStore(pinia);

  const [appInfo, authInfo] = await Promise.all([getAppInfo(), getAuthInfo()]);

  appInfoStore.setAppInfo(appInfo);

  if (authInfo.isLoggedIn) {
    userInfoStore.login(authInfo);
  } else {
    userInfoStore.logout();
  }
}
