<template>
  <auth-layout>
    <div
      class="portal__card__default bg-white px-4 py-4 container password__reset__dialog"
    >
      <!-- Branding -->
      <auth-branding />

      <!-- Info -->
      <div class="password__reset__info">
        <div class="portal__h5 password__reset__info__heading">
          {{ $t("modules.auth.resetPassword.title") }}
        </div>
        <div class="password__reset__info__text">
          {{ $t("modules.auth.resetPassword.text") }}
        </div>
      </div>

      <Form
        @submit="doSubmit"
        class="portal__form password__reset__form"
        :validation-schema="schema"
      >
        <!-- Email -->
        <Field name="email" v-slot="{ field, errorMessage }">
          <div class="portal__input__wrapper password__reset__form__email">
            <input
              id="resetPasswordEmail"
              type="email"
              class="portal__form__control"
              :class="{ 'is-invalid': errorMessage }"
              :placeholder="$t('modules.auth.resetPassword.fields.email')"
              v-bind="field"
            />
            <label for="resetPasswordEmail" class="portal__form__label">
              {{ $t("modules.auth.resetPassword.fields.email") }}
            </label>
            <div class="invalid-feedback" v-if="errorMessage">
              {{ $t(`validations.${errorMessage}`) }}
            </div>
          </div>
        </Field>

        <!-- Reset Passwort Button -->
        <div class="portal__buttons__group">
          <button
            class="portal__button__primary password__reset__password__reset__button"
            type="submit"
          >
            {{ $t("modules.auth.resetPassword.actions.submit") }}
          </button>

          <!-- Cancel Link -->
          <router-link
            :to="{ name: 'login' }"
            class="portal__button__secondary password__reset__cancel__button"
          >
            {{ $t("modules.auth.resetPassword.actions.cancel") }}
          </router-link>
        </div>
      </Form>
    </div>
  </auth-layout>
</template>

<script setup>
import AuthLayout from "@app/layouts/auth/AuthLayout.vue";
import AuthBranding from "@app/modules/auth/components/AuthBranding.vue";
import { ref } from "vue";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

const isProcessing = ref(false);
const schema = yup.object({
  email: yup.string().email().required(),
});

const doSubmit = async () => {
  isProcessing.value = true;
  try {
    // TODO: Success
  } catch {
    // TODO: Error
  }
  isProcessing.value = false;
};
</script>
