import { defineStore } from "pinia";
import {
  getCategories,
  searchAssets,
} from "@app/modules/asset-list/api/assetListApi.js";
import { flattenTree } from "@app/common/helper/flattenTree.js";

export const useAssetListStore = defineStore("assetList", {
  state: () => {
    return {
      isInitiating: true,
      areAssetsLoadingInitial: true,
      areAssetLoadingNextPage: false,
      categoryTrees: [],
      categoryNames: {},
      assets: [],
      pagination: {
        page: null,
        pages: null,
        total: null,
      },

      activePresetName: null,
      activeSearch: "",
      activeFilters: {
        type: [],
      },
      activeCategories: [],
      activeSort: "default",

      workingFilters: {
        type: [],
      },
      workingCategories: [],
      workingSort: "default",
    };
  },
  actions: {
    syncWorkingSort() {
      this.workingSort = this.activeSort;
    },
    adoptWorkingSort() {
      this.activeSort = this.workingSort;
    },
    syncWorkingCategories() {
      this.workingCategories = [...this.activeCategories];
    },
    adoptWorkingCategories() {
      this.activeCategories = [...this.workingCategories];
    },
    syncWorkingFilters() {
      this.workingFilters.type = [...this.activeFilters.type];
    },
    adoptWorkingFilters() {
      this.activeFilters.type = [...this.workingFilters.type];
    },

    async initialize() {
      this.isInitiating = true;
      this.areAssetsLoadingInitial = true;
      this.assets = [];
      this.pagination.page = 0;
      this.pagination.pages = null;
      this.pagination.total = null;
      if (this.categoryTrees.length === 0) {
        this.categoryTrees = await getCategories();

        this.categoryTrees.forEach((categoryTree) => {
          flattenTree(categoryTree).forEach((node) => {
            this.categoryNames[node.id] = node.title;
          });
        });
      }
      this.isInitiating = false;
    },

    async triggerNewSearch({ removePresetName = true } = {}) {
      this.areAssetsLoadingInitial = true;
      this.assets = [];
      this.pagination.page = 0;
      this.pagination.pages = null;
      this.pagination.total = null;
      if (removePresetName) {
        this.activePresetName = null;
      }
      const result = await this.loadAssetsWithFilters(
        this.pagination.page + 1,
        24
      );
      this.pagination.page = result.pagination.page;
      this.pagination.pages = result.pagination.pages;
      this.pagination.total = result.pagination.total;
      this.assets.push(...result.data);
      this.areAssetsLoadingInitial = false;
    },

    async loadNextPage() {
      this.areAssetLoadingNextPage = true;
      const result = await this.loadAssetsWithFilters(
        this.pagination.page + 1,
        24
      );
      this.pagination.page = result.pagination.page;
      this.pagination.pages = result.pagination.pages;
      this.pagination.total = result.pagination.total;
      this.assets.push(...result.data);
      this.areAssetLoadingNextPage = false;
    },

    loadAssetsWithFilters(page, limit) {
      const filters = {};

      if (this.activeSearch) {
        filters.name = this.activeSearch;
      }

      if (this.activeCategories.length) {
        filters.category = [...this.activeCategories];
      }

      if (this.activeFilters.type.length) {
        filters.asset_type = [...this.activeFilters.type];
      }

      switch (this.activeSort) {
        case "order_by_name_asc":
          filters.order_by_name = "asc";
          break;
        case "order_by_name_desc":
          filters.order_by_name = "desc";
          break;
        case "order_by_filesize_asc":
          filters.order_by_filesize = "asc";
          break;
        case "order_by_filesize_desc":
          filters.order_by_filesize = "desc";
          break;
        case "order_by_creation_date_asc":
          filters.order_by_creation_date = "asc";
          break;
        case "order_by_creation_date_desc":
          filters.order_by_creation_date = "desc";
          break;
        case "order_by_modification_date_asc":
          filters.order_by_modification_date = "asc";
          break;
        case "order_by_modification_date_dec":
          filters.order_by_modification_date = "desc";
          break;
      }
      return searchAssets(page, limit, filters);
    },

    resetAllFilters() {
      this.activeSearch = "";
      this.activeCategories = [];
      this.activeFilters.type = [];
    },
    removeActiveCategory(categoryId) {
      this.activeCategories = this.activeCategories.filter(
        (c) => c !== categoryId
      );
    },
    removeActiveTypeFilter(value) {
      this.activeFilters.type = this.activeFilters.type.filter(
        (v) => v !== value
      );
    },
  },

  getters: {
    areThereMorePages: (state) =>
      state.pagination.page < state.pagination.pages,

    showMoreButton: (state) =>
      state.areAssetsLoadingInitial !== true &&
      state.areAssetLoadingNextPage !== true &&
      state.areThereMorePages === true,

    areFiltersActive: (state) => state.activeFilters.type.length > 0,
    areCategoriesActive: (state) => state.activeCategories.length > 0,
    areAnyFiltersActive: (state) =>
      state.activeSearch ||
      state.activeFilters.type.length > 0 ||
      state.activeCategories.length > 0,

    categoryName: (state) => {
      return (categoryId) => state.categoryNames[categoryId] || categoryId;
    },
  },
});
