export default {
  groups: {
    information: "Information",
  },
  fields: {
    fileSize: "File size",
    fileExtension: "File type",
  },
  actions: {
    download: "Download",
    addToBasket: "Add to basket",
    removeFromBasket: "Remove from basket",
  },
};
