export default {
  groups: {
    information: "Informationen",
  },
  fields: {
    fileSize: "Dateigröße",
    fileExtension: "Datei-Typ",
  },
  actions: {
    download: "Download",
    addToBasket: "Hinzufügen",
    removeFromBasket: "Entfernen",
  },
};
