<template>
  <auth-layout>
    <div
      class="portal__card__default bg-white px-4 py-4 container registration__dialog"
    >
      <!-- Branding -->
      <auth-branding />

      <div v-show="!registrationSuccess">
        <!-- Info -->
        <div class="registration__info">
          <div class="portal__h5 registration__info__heading">
            {{ $t("modules.auth.registration.title") }}
          </div>
        </div>

        <Form
          @submit="doRegistration"
          class="portal__form registration__form"
          :validation-schema="schema"
          v-show="!registrationSuccess"
        >
          <!-- Name -->
          <Field name="name" v-slot="{ field, errorMessage }">
            <div class="portal__input__wrapper registration__form__name">
              <input
                id="registrationName"
                type="text"
                class="portal__form__control"
                :class="{ 'is-invalid': errorMessage }"
                :placeholder="$t('modules.auth.registration.fields.name')"
                v-bind="field"
              />
              <label for="registrationName" class="portal__form__label">
                {{ $t("modules.auth.registration.fields.name") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- Company -->
          <Field name="company" v-slot="{ field, errorMessage }">
            <div class="portal__input__wrapper registration__form__company">
              <input
                id="registrationCompany"
                type="text"
                class="portal__form__control"
                :class="{ 'is-invalid': errorMessage }"
                :placeholder="$t('modules.auth.registration.fields.company')"
                v-bind="field"
              />
              <label for="registrationCompany" class="portal__form__label">
                {{ $t("modules.auth.registration.fields.company") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- Department -->
          <Field name="department" v-slot="{ field, errorMessage }">
            <div class="portal__input__wrapper registration__form__department">
              <input
                id="registrationDepartment"
                type="text"
                class="portal__form__control"
                :class="{ 'is-invalid': errorMessage }"
                :placeholder="$t('modules.auth.registration.fields.department')"
                v-bind="field"
              />
              <label for="registrationDepartment" class="portal__form__label">
                {{ $t("modules.auth.registration.fields.department") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- Position -->
          <Field name="position" v-slot="{ field, errorMessage }">
            <div class="portal__input__wrapper registration__form__position">
              <input
                id="registrationPosition"
                type="text"
                class="portal__form__control"
                :class="{ 'is-invalid': errorMessage }"
                :placeholder="$t('modules.auth.registration.fields.position')"
                v-bind="field"
              />
              <label for="registrationPosition" class="portal__form__label">
                {{ $t("modules.auth.registration.fields.position") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- E-Mail -->
          <Field name="email" v-slot="{ field, errorMessage }">
            <div class="portal__input__wrapper registration__form__email">
              <input
                id="registrationEmail"
                type="email"
                class="portal__form__control"
                :class="{ 'is-invalid': errorMessage }"
                :placeholder="$t('modules.auth.registration.fields.email')"
                v-bind="field"
              />
              <label for="registrationEmail" class="portal__form__label">
                {{ $t("modules.auth.registration.fields.email") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- Comment -->
          <Field name="comment" v-slot="{ field, errorMessage }">
            <div class="portal__input__wrapper registration__form__comment">
              <textarea
                id="registrationComment"
                class="portal__form__control portal__form__textarea"
                :class="{ 'is-invalid': errorMessage }"
                :placeholder="$t('modules.auth.registration.fields.comment')"
                v-bind="field"
              />
              <label for="registrationComment" class="portal__form__label">
                {{ $t("modules.auth.registration.fields.comment") }}&nbsp;<span
                  class="portal__form__hint"
                  >(optional)</span
                >
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- Terms and Condition Check -->
          <Field
            name="terms"
            type="checkbox"
            :value="true"
            :uncheckedValue="false"
            v-slot="{ field, errorMessage }"
          >
            <div class="portal__check__wrapper registration__form__agb pb-2">
              <input
                id="registrationTerms"
                type="checkbox"
                class="portal__form__checkbox registration__form__agb__checkbox"
                :class="{ 'is-invalid': errorMessage }"
                :value="true"
                v-bind="field"
              />
              <label
                for="registrationTerms"
                class="portal__form__checkbox__label registration__form__agb__text"
              >
                {{ $t("modules.auth.registration.texts.termsV1") }}
                <a
                  :href="appInfo.links.privacyPolicy"
                  :title="$t('modules.auth.registration.texts.termsV2')"
                  >{{ $t("modules.auth.registration.texts.termsV2") }}</a
                >
                {{ $t("modules.auth.registration.texts.termsV3") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <!-- User data handling Check -->
          <Field
            name="userData"
            type="checkbox"
            :value="true"
            :uncheckedValue="false"
            v-slot="{ field, errorMessage }"
          >
            <div class="portal__check__wrapper registration__form__userData">
              <input
                id="userData"
                type="checkbox"
                class="portal__form__checkbox registration__form__userData__checkbox"
                :class="{ 'is-invalid': errorMessage }"
                :value="true"
                v-bind="field"
              />
              <label
                for="userData"
                class="portal__form__checkbox__label registration__form__userData__text"
              >
                {{ $t("modules.auth.registration.texts.userData") }}
              </label>
              <div class="invalid-feedback" v-if="errorMessage">
                {{ $t(`validations.${errorMessage}`) }}
              </div>
            </div>
          </Field>

          <div class="portal__buttons__group">
            <!-- Register Button -->
            <button
              class="portal__button__primary registration__submit__button"
              type="submit"
            >
              {{ $t("modules.auth.registration.actions.submit") }}
            </button>

            <!-- Cancel Link -->
            <router-link
              :to="{ name: 'login' }"
              class="portal__button__secondary registration__cancel__button"
            >
              {{ $t("modules.auth.registration.actions.cancel") }}
            </router-link>
          </div>
        </Form>
      </div>

      <div class="registration__successful" v-show="registrationSuccess">
        <div class="registration__info">
          <div class="portal__h5 registration__info__heading">
            {{ $t("modules.auth.registration.success.title") }}
          </div>
        </div>

        <p>
          {{ $t("modules.auth.registration.success.text") }}
        </p>

        <router-link
          :to="{ name: 'login' }"
          class="registration__cancel__button"
        >
          {{ $t("modules.auth.registration.success.actions.login") }}
        </router-link>
      </div>
    </div>
  </auth-layout>
</template>

<script setup>
import AuthBranding from "@app/modules/auth/components/AuthBranding.vue";
import AuthLayout from "@app/layouts/auth/AuthLayout.vue";
import { ref } from "vue";
import { register } from "@app/modules/auth/api/authApi.js";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

let registrationSuccess = ref(false);
const isProcessing = ref(false);
const schema = yup.object({
  name: yup.string().required(),
  company: yup.string().required(),
  department: yup.string().required(),
  position: yup.string().required(),
  email: yup.string().email().required(),
  terms: yup
    .bool()
    .oneOf([true], "acceptTermsAndConditions")
    .required("acceptTermsAndConditions"),
  userData: yup
    .bool()
    .oneOf([true], "acceptUserData")
    .required("acceptUserData"),
});

const doRegistration = async (values, actions) => {
  isProcessing.value = true;

  try {
    await register(
      values.name,
      values.company,
      values.department,
      values.position,
      values.email,
      values.comment
    );
    registrationSuccess.value = true;
  } catch (e) {
    console.log(e);

    if (
      e.response.data.error === "Validation failed" &&
      e.response.data.violations
    ) {
      Object.keys(e.response.data.violations).forEach((key) => {
        actions.setFieldError(
          key,
          e.response.data?.violations[key][0]["custom_code"]
        );
      });
    }
  }
  isProcessing.value = false;
};
const appInfo = useAppInfoStore();
</script>
