<template>
  <div class="portal__treeview__node">
    <div class="portal__treeview__node__content">
      <!-- Open Close -->
      <button
        class="portal__button__icon portal__treeview__node__icon"
        v-if="hasChildren"
        @click.prevent="onToggleOpen()"
      >
        <span
          class="material-icons-outlined"
          :class="isOpen ? 'opened' : 'closed'"
          >chevron_right</span
        >
      </button>

      <!-- Checkbox -->
      <div class="portal__treeview__node__checkbox">
        <span
          class="portal__treeview__node__checkbox__icon portal__treeview__node__checkbox__icon--checked material-icons"
          @click.prevent="toggleSelection()"
          v-if="isSelected"
        >
          check_box
        </span>
        <span
          class="portal__treeview__node__checkbox__icon portal__treeview__node__checkbox__icon--indeterminate material-icons"
          @click.prevent="toggleSelection()"
          v-else-if="isIndeterminate"
        >
          indeterminate_check_box
        </span>
        <span
          class="portal__treeview__node__checkbox__icon portal__treeview__node__checkbox__icon--unchecked material-icons"
          @click.prevent="toggleSelection()"
          v-else
        >
          check_box_outline_blank
        </span>

        <label
          :for="node.id"
          class="portal__form__checkbox__label ms-1"
          @click.prevent="toggleSelection()"
        >
          <!-- Title -->
          {{ node.title }}
        </label>
      </div>
    </div>
    <!-- Children -->
    <template v-if="hasChildren">
      <div v-if="isOpen">
        <portal-tree-view-node
          v-for="treeNode in node.children"
          :key="treeNode.id"
          :node="treeNode"
          @change="$emit('change', $event)"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, inject } from "vue";

const props = defineProps({
  node: {
    required: true,
    type: Object,
  },
});

const isSelectedInj = inject("isSelected");
const isSelected = computed(() => isSelectedInj(props.node));
const isOpenInj = inject("isOpen");
const isOpen = computed(() => isOpenInj(props.node));
const isIndeterminateInj = inject("isIndeterminate");
const isIndeterminate = computed(() => isIndeterminateInj(props.node));

const hasChildren = computed(
  () => props.node.children && props.node.children.length
);

const toggleSelectionInj = inject("toggleSelection");
const toggleSelection = () => {
  toggleSelectionInj(props.node, !isSelected.value);
};

const toggleOpen = inject("toggleOpen");
const onToggleOpen = () => {
  toggleOpen(props.node, !isOpen.value);
};
</script>
