import { defineStore } from "pinia";

export const useAppInfoStore = defineStore("appInfo", {
  state: () => {
    return {
      branding: {
        appName: "",
        appLogo: "",
        background: "",
      },
      brands: [],
      channels: [],
      links: {
        imprint: "",
        privacyPolicy: "",
        resetPassword: "",
      },
    };
  },
  actions: {
    setAppInfo(appInfo) {
      this.branding.appName = appInfo.branding.appName;
      this.branding.appLogo = appInfo.branding.appLogo;
      this.branding.background = appInfo.branding.background;
      this.brands = appInfo.brands ?? [];
      this.channels = appInfo.channels ?? [];
      this.links.imprint = appInfo.links.imprint;
      this.links.privacyPolicy = appInfo.links.privacyPolicy;
      this.links.resetPassword = appInfo.links.resetPassword;
    },
  },
  getters: {
    hasMultipleBrands: (state) => state.brands.length > 1,
  },
});
