<template>
  <portal-dialog ref="dialog">
    <template v-slot:activator="{ open }">
      <button
        @click.prevent="open()"
        class="portal__button__icon app__header__actions__nav__link"
      >
        <span
          class="material-icons-outlined app__language__selection__trigger__icon"
        >
          logout
        </span>
      </button>
    </template>
    <template v-slot="{ close }">
      <!-- Header -->
      <div class="dialog__header">
        <!--  Icon -->
        <div class="dialog__header__icon">
          <span class="material-icons-outlined">logout</span>
        </div>

        <!--  Title -->
        <div class="portal__h5 dialog__header__title">
          {{ $t("modules.appLayout.logout.dialogTitle") }}
        </div>

        <!-- Close Button -->
        <div class="dialog__header__close">
          <button
            @click.prevent="close()"
            class="dialog__header__close__button"
          >
            <span class="dialog__header__close__icon material-icons-outlined"
              >close</span
            >
          </button>
        </div>
      </div>

      <div class="dialog__body app__language__selection__dialog">
        <!-- Content -->
        <div class="dialog__content">
          {{ $t("modules.appLayout.logout.dialogQuestion") }}
        </div>

        <!-- Actions -->
        <div class="portal__buttons__group dialog__actions">
          <button
            class="portal__button__primary portal__button__with__icon"
            @click.prevent="doLogout()"
          >
            <span class="material-icons-outlined">logout</span>
            {{ $t("modules.appLayout.logout.actions.logout") }}
          </button>
          <button
            class="portal__button__secondary portal__button__with__icon"
            @click.prevent="close()"
          >
            <span class="material-icons-outlined">close</span>
            {{ $t("modules.appLayout.logout.actions.cancel") }}
          </button>
        </div>
      </div>
    </template>
  </portal-dialog>
</template>

<script setup>
import PortalDialog from "@app/common/components/PortalDialog.vue";
import { ref } from "vue";
import { logout } from "presse-portal-frontend/src/modules/auth/api/authApi";
import router from "@app/router";
import { useUserInfoStore } from "@app/common/stores/userInfoStore";

const dialog = ref();

const doLogout = async () => {
  await logout();
  useUserInfoStore().logout();
  await router.push({ name: "login" });
};
</script>
