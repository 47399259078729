<template>
  <app-layout>
    <!-- Loader -->
    <transition name="fade" mode="out-in">
      <page-loader v-if="assetListStore.isInitiating" />

      <!-- Page -->
      <div v-else>
        <div class="container">
          <!-- Preset name -->
          <div
            v-if="assetListStore.activePresetName"
            class="portal__container__spacing__default"
          >
            <span
              class="portal__tagline fs-5 badge bg-light asset__list__active__preset__name"
            >
              <span class="material-icons-outlined">adjust</span>
              {{ assetListStore.activePresetName }}
            </span>
          </div>

          <!-- Filter -->
          <asset-list-page-filter />

          <!-- Reset filters & amount -->
          <div
            v-if="!assetListStore.areAssetsLoadingInitial"
            class="portal__container__spacing__default d-flex justify-content-between"
          >
            <div
              v-if="assetListStore.areAnyFiltersActive"
              @click.prevent="resetAllFilters()"
              class="portal__link"
            >
              {{ $t("modules.assetList.actions.showAll") }}
            </div>
            <div class="portal__tagline ms-auto">
              {{
                $t("modules.assetList.actions.results", {
                  count: assetListStore.pagination.total,
                })
              }}
            </div>
          </div>

          <!-- Loader for page 1 (also resets the InfiniteLoading) -->
          <transition name="fade" mode="out-in">
            <!-- Loader (only for first page loading) -->
            <page-loader v-if="assetListStore.areAssetsLoadingInitial" />

            <!-- No results -->
            <div v-else-if="assetListStore.assets.length === 0">
              <!-- No results for search/filter -->
              <div
                v-if="assetListStore.areAnyFiltersActive"
                class="portal__container__spacing__default text-center portal__tagline"
              >
                {{ $t("modules.assetList.messages.noResultsFilter") }}
              </div>

              <!-- No results overall -->
              <div
                v-else
                class="portal__container__spacing__default text-center portal__tagline"
              >
                {{ $t("modules.assetList.messages.noResults") }}
              </div>
            </div>

            <!-- Asset-List -->
            <div v-else>
              <!-- List -->
              <asset-list-page-list :items="assetListStore.assets" />

              <!-- Infinite Loader -->
              <InfiniteLoading :first-load="false" @infinite="loadNextPage">
                <template #spinner>
                  <div
                    class="portal__container__spacing__default text-center portal__tagline"
                  >
                    {{ $t("modules.assetList.messages.loading") }}
                  </div>
                </template>
                <template #complete>
                  <div
                    class="portal__container__spacing__default text-center portal__tagline"
                  >
                    {{ $t("modules.assetList.messages.complete") }}
                  </div>
                </template>
              </InfiniteLoading>
              <div
                v-if="assetListStore.showMoreButton"
                class="portal__container__spacing__default"
              >
                <button
                  class="portal__button__secondary"
                  @click="loadNextPage()"
                >
                  {{ $t("modules.assetList.actions.loadMore") }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </app-layout>
</template>

<script setup>
import { onBeforeMount, onMounted, watch } from "vue";
import AppLayout from "@app/layouts/app/AppLayout.vue";
import AssetListPageList from "@app/modules/asset-list/pages/asset-list/AssetListPageList.vue";
import AssetListPageFilter from "@app/modules/asset-list/pages/asset-list/AssetListPageFilter.vue";
import PageLoader from "@app/common/components/PageLoader.vue";
import InfiniteLoading from "v3-infinite-loading";
import { useAssetListStore } from "@app/modules/asset-list/store/assetListStore.js";
import router from "@app/router";

const assetListStore = useAssetListStore();

const loadNextPage = async ($state = null) => {
  await assetListStore.loadNextPage();

  $state !== null && assetListStore.areThereMorePages
    ? $state.loaded()
    : $state.complete();
};

const resetAllFilters = () => {
  assetListStore.resetAllFilters();
  assetListStore.triggerNewSearch();
};

watch(
  () => [
    assetListStore.activeSearch,
    assetListStore.activeFilters,
    assetListStore.activeCategories,
  ],
  () => {
    const query = {};
    if (assetListStore.activeSearch) {
      query.q = assetListStore.activeSearch;
    }
    if (assetListStore.activeCategories.length) {
      query.c = assetListStore.activeCategories;
    }
    if (assetListStore.activeFilters.type.length) {
      query.type = assetListStore.activeFilters.type;
    }
    if (assetListStore.activeSort) {
      query.sort = assetListStore.activeSort;
    }

    router.replace({ query });
  },
  {
    deep: true,
  }
);

onBeforeMount(async () => {
  await assetListStore.initialize();
});

// Mounted
onMounted(async () => {
  adoptFiltersFromUrl();
  await assetListStore.triggerNewSearch({ removePresetName: false });
});

function adoptFiltersFromUrl() {
  const query = router.currentRoute.value.query;
  assetListStore.activeSearch = query.q || "";

  const categoriesFromQuery = query.c
    ? Array.isArray(query.c)
      ? query.c.map((c) => parseInt(c, 10))
      : [parseInt(query.c, 10)]
    : [];
  assetListStore.activeCategories = categoriesFromQuery;

  const typeFromQuery = query.type
    ? Array.isArray(query.type)
      ? query.type
      : [query.type]
    : [];
  assetListStore.activeFilters.type = typeFromQuery;

  assetListStore.activeSort = query.sort || "default";
  assetListStore.activePresetName = query.presetName || null;
}
</script>
