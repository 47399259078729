<template>
  <app-layout>
    <!-- Loader -->
    <transition name="fade" mode="out-in">
      <page-loader v-if="isLoading" />

      <!-- Page -->
      <div v-else>
        <div class="basket__page container portal__container__spacing__default">
          <div class="row g-4">
            <!-- Amount -->
            <div class="portal__tagline basket__page__amount col-12">
              Insgesamt: {{ basketStore.amountInBasket }}
            </div>
            <div class="col-12 col-md-8 basket__page__list">
              <!-- Item List -->
              <basket-page-list :items="basketStore.items" />
              <!-- Empty Basket -->
              <basket-page-empty-button v-if="!basketStore.basketIsEmpty" />
              <hr />
            </div>
            <div class="col-12 col-md-4 basket__page__actions">
              <div class="basket__page__actions__wrapper">
                <!-- Quality -->
                <basket-page-quality-selection />

                <div class="portal__buttons__group">
                  <!-- Download -->
                  <button
                    class="portal__button__with__icon portal__button__primary basket__page__actions__download"
                    :disabled="
                      isProcessing || basketStore.basketIsEmpty || isLoading
                    "
                    @click.prevent="download()"
                  >
                    <span class="material-icons-outlined">file_download</span>
                    {{ $t("modules.basket.download") }}
                  </button>

                  <!-- Share -->
                  <!--<button
                    class="portal__button__with__icon portal__button__secondary basket__page__actions__share"
                    :disabled="
                      isProcessing || basketStore.basketIsEmpty || isLoading
                    "
                    @click.prevent="send()"
                  >
                    <span class="material-icons-outlined">ios_share</span>
                    {{ $t("modules.basket.send") }}
                  </button>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </app-layout>
</template>

<script setup>
import AppLayout from "@app/layouts/app/AppLayout.vue";
import { useBasketStore } from "@app/common/stores/basketStore.js";
import BasketPageList from "@app/modules/basket/pages/basket/BasketPageList.vue";
import BasketPageEmptyButton from "@app/modules/basket/pages/basket/BasketPageEmptyButton.vue";
import BasketPageQualitySelection from "@app/modules/basket/pages/basket/BasketPageQualitySelection.vue";
import { onMounted, ref } from "vue";
import PageLoader from "@app/common/components/PageLoader.vue";
import { assetList, downloadBasket } from "@app/modules/basket/api/basketApi";

const basketStore = useBasketStore();
let isLoading = ref(true);
let isProcessing = ref(false);

// Mounted
onMounted(() => {
  fillBasketAssets();
});

async function fillBasketAssets() {
  isLoading.value = true;
  basketStore.items = await assetList(basketStore.basketIds);
  isLoading.value = false;
}

async function download() {
  isProcessing.value = true;
  let basketLink = await downloadBasket(
    basketStore.basketIds,
    basketStore.selectedChannel !== "" ? basketStore.selectedChannel : null
  );
  let clickTarget = document.createElement("a");
  clickTarget.href = basketLink;
  clickTarget.target = "_blank";
  clickTarget.click();
  isProcessing.value = false;
}

// async function send() {
//   isProcessing.value = true;
//   //let basketLink = await downloadBasket(basketStore.basketIds);
//   let clickTarget = document.createElement("a");
//   clickTarget.href =
//     "mailto:t.mueller@eikona-media.de?subject=The%20busted%20Basket&body=Test...%20https%3A%2F%2Ftessa-dev.zegxx-web51.eimed-server.de%2Fui%2Findex.php%3Fbereich%3Dui%26modul_id%3D104%26klasse%3Dbezuege_erstellen%26com%3Derstellen%26vorlage%3Dbezug%26bezug_typ%3Ddownload%26bezug_schluessel%3Daee39c022811007872dca756ba295abd%26bezug_datei_name%3D2022-06-30_469a9cc2c725adf8b23a26de369b8521%26sprache%3D";
//   clickTarget.target = "_blank";
//   clickTarget.click();
//   isProcessing.value = false;
// }
</script>
