<template>
  <app-layout>
    <!-- Loader -->
    <transition name="fade" mode="out-in">
      <!-- Page -->
      <div>
        <!-- Brand Selection -->
        <collections-page-brand-selection
          class="container portal__container__spacing__default"
          v-model="selectedBrand"
          v-if="appInfo.hasMultipleBrands"
        />

        <page-loader v-if="isLoading" />

        <!-- No results -->
        <div
          v-else-if="collections.length === 0"
          class="container portal__container__spacing__default"
        >
          <!-- No results for search/filter -->
          <div
            v-if="selectedBrand !== ''"
            class="portal__container__spacing__default text-center portal__tagline"
          >
            {{ $t("modules.collections.messages.noResultsFilter") }}
          </div>

          <!-- No results overall -->
          <div
            v-else
            class="portal__container__spacing__default text-center portal__tagline"
          >
            {{ $t("modules.collections.messages.noResults") }}
          </div>
        </div>

        <!-- Collections -->
        <collections-page-collection-list
          class="container portal__container__spacing__default"
          :collections="collections"
          v-else
        />
      </div>
    </transition>
  </app-layout>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import CollectionsPageBrandSelection from "@app/modules/collections/pages/collections/CollectionsPageBrandSelection.vue";
import CollectionsPageCollectionList from "@app/modules/collections/pages/collections/CollectionsPageCollectionList.vue";
import AppLayout from "@app/layouts/app/AppLayout.vue";
import { searchCollections } from "@app/modules/collections/api/collectionsApi.js";
import PageLoader from "@app/common/components/PageLoader.vue";
import router from "@app/router";
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfo = useAppInfoStore();
const isLoading = ref(true);
const collections = ref(null);

const selectedBrand = ref(
  appInfo.hasMultipleBrands ? router.currentRoute.value.query.brand || "" : ""
);

onMounted(() => {
  loadCollections();
});

const loadCollections = async () => {
  isLoading.value = true;
  collections.value = await searchCollections(
    selectedBrand.value !== "" ? selectedBrand.value : undefined
  );
  router.replace({
    query: selectedBrand.value.length > 0 ? { brand: selectedBrand.value } : {},
  });

  isLoading.value = false;
};

watch(selectedBrand, () => loadCollections());
</script>
