import appLayoutModule from "@app/layouts/app/translations/en_US.js";
import authLayoutModule from "@app/layouts/auth/translations/en_US.js";

import assetDetailModule from "@app/modules/asset-detail/translations/en_US.js";
import assetListModule from "@app/modules/asset-list/translations/en_US.js";
import authModule from "@app/modules/auth/translations/en_US.js";
import basketModule from "@app/modules/basket/translations/en_US.js";
import collectionsModule from "@app/modules/collections/translations/en_US.js";
import dashboardModule from "@app/modules/dashboard/translations/en_US.js";

export default {
  modules: {
    appLayout: appLayoutModule,
    authLayout: authLayoutModule,

    assetDetail: assetDetailModule,
    assetList: assetListModule,
    auth: authModule,
    basket: basketModule,
    collections: collectionsModule,
    dashboard: dashboardModule,
  },
  languages: {
    de_DE: "German",
    en_US: "English",
  },
  qualities: {
    original: "Original",
  },
  validations: {
    acceptTermsAndConditions: "Please confirm our privacy policy.",
    acceptUserData: "You must confirm the storage of your data.",
    fieldRequired: "Is a required field.",
    is_null_error: "Is a required field.",
    is_blank_error: "Is a required field.",
    mustBeValidEmail: "Must contain a valid email.",
    email_invalid_format_error: "Must contain a valid email.",
    invalidCredentials: "Invalid credentials.",
  },
};
