<template>
  <portal-dialog @before-open="onBeforeOpen()" ref="dialog">
    <template v-slot:activator="{ open }">
      <button
        class="portal__button__icon asset__list__category__trigger__button"
        @click.prevent="open()"
        :class="{ active: assetListStore.areCategoriesActive }"
      >
        <span
          class="material-icons-outlined asset__list__category__trigger__icon"
        >
          folder
          <span
            v-if="assetListStore.areCategoriesActive"
            class="position-absolute top-0 start-100 translate-middle p-1 bg-primary border border-light rounded-circle"
          />
        </span>
      </button>
    </template>
    <template v-slot="{ close }">
      <!-- Header -->
      <div class="dialog__header asset__list__category__dialog__header">
        <!--  Icon -->
        <div class="asset__list__category__dialog__icon">
          <span class="material-icons-outlined">folder</span>
        </div>

        <!--  Title -->
        <div
          class="portal__h5 dialog__header__title asset__list__category__dialog__title"
        >
          {{ $t("modules.assetList.categoryDialog.title") }}
        </div>

        <!-- Submit Button -->
        <div
          class="dialog__header__close asset__list__category__dialog__submit"
        >
          <button
            class="dialog__header__close__button"
            @click.prevent="submit()"
          >
            <span class="material-icons-outlined">check</span>
          </button>
        </div>

        <!-- Close Button -->
        <div class="dialog__header__close asset__list__category__dialog__close">
          <button
            class="dialog__header__close__button"
            @click.prevent="close()"
          >
            <span class="material-icons-outlined">close</span>
          </button>
        </div>
      </div>

      <div class="dialog__body asset__list__category__dialog__content">
        <!-- Category tree -->
        <portal-tree-view
          :tree="assetListStore.categoryTrees"
          v-model:open-categories="openCategories"
          v-model:selected-categories="assetListStore.workingCategories"
        />
      </div>
    </template>
  </portal-dialog>
</template>

<script setup>
import PortalDialog from "@app/common/components/PortalDialog.vue";
import PortalTreeView from "@app/common/components/PortalTreeView.vue";
import { ref } from "vue";
import { useAssetListStore } from "@app/modules/asset-list/store/assetListStore.js";

const assetListStore = useAssetListStore();
const openCategories = ref([]);
const dialog = ref();

const submit = () => {
  assetListStore.adoptWorkingCategories();
  assetListStore.triggerNewSearch();
  dialog.value.close();
};

const onBeforeOpen = () => {
  assetListStore.syncWorkingCategories();
  openCategories.value = getOpenCategories(
    assetListStore.categoryTrees,
    assetListStore.workingCategories
  );
};

function getOpenCategories(rootNodes, selectedCategories) {
  const openCats = [];

  function getOpenCategoriesForNode(node, path = []) {
    if (selectedCategories.includes(node.id)) {
      openCats.push(...path);
    }
    if (node.children && node.children.length) {
      node.children.forEach((child) =>
        getOpenCategoriesForNode(child, [...path, node.id])
      );
    }
  }

  rootNodes.forEach((node) => getOpenCategoriesForNode(node));

  // Make unique
  return openCats.filter((v, i, a) => a.indexOf(v) === i);
}
</script>
