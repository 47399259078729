<template>
  <div class="d-flex auth__branding">
    <div class="auth__branding__logo">
      <img :src="appInfoStore.branding.appLogo" />
    </div>
    <div class="portal__tagline auth__branding__title">
      {{ appInfoStore.branding.appName }}
    </div>
  </div>
</template>

<script setup>
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfoStore = useAppInfoStore();
</script>
