<template>
  <a
    class="portal__card__cover__image dashboard__highlights__tile"
    :href="highlight.link"
    :target="highlight.openInNewTab ? '_blank' : '_self'"
    :style="style"
  >
    <div class="card-img-overlay dashboard__highlights__content">
      <div class="portal__h5 dashboard__highlights__heading">
        {{ highlight.heading }}
      </div>
      <div class="dashboard__highlights__subheading">
        {{ highlight.subheading }}
      </div>
    </div>
  </a>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  highlight: Object,
});

const style = computed(() => {
  return {
    backgroundImage: `url(${props.highlight.background})`,
  };
});
</script>
