import { createI18n } from "vue-i18n";
import de_DE from "@app/translations/de_DE.js";
import en_US from "@app/translations/en_US.js";
import { watch } from "vue";
import router from "@app/router";

let initialLocale = localStorage.getItem("language");
if (!["de_DE", "en_US"].includes(initialLocale)) {
  initialLocale = "de_DE";
}

const i18n = createI18n({
  locale: initialLocale,
  fallbackLocale: "en_US",
  globalInjection: true,
  messages: {
    de_DE,
    en_US,
  },
});

watch(i18n.global.locale, () => {
  localStorage.setItem("language", i18n.global.locale.value);
  router.go(0);
});

export default i18n;

export function getLocaleForBackend() {
  switch (i18n.global.locale.value) {
    default:
      return i18n.global.locale.value;
  }
}
