import { axiosWithAuth } from "@app/common/plugins/axios.js";

/**
 * @typedef AssetListItem
 * @type {object}
 * @property {number} id
 * @property {string} name
 * @property {number} size
 * @property {string} extension
 * @property {string} image
 * @property {string} download
 * @property {ChannelDownload[]} channelDownloads
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @param assets
 * @returns {Promise<AssetListItem[]>}
 */
export function assetList(assets = []) {
  return axiosWithAuth
    .post("/api/v1/modules/assets/list", {
      assets,
    })
    .then((response) => response.data.data);
}

/**
 * @param assets
 * @param channel
 * @returns {Promise<string>}
 */
export function downloadBasket(assets = [], channel) {
  return axiosWithAuth
    .post("/api/v1/modules/basket/download", {
      assets,
      channel,
    })
    .then((response) => response.data.data.download);
}
