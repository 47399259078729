<template>
  <div class="asset__list__list__item">
    <!-- Preview -->
    <div
      class="portal__card__default bg-light-300 asset__list__list__item__preview"
    >
      <!-- Checkbox -->
      <label class="asset__list__list__item__checkbox__label">
        <input
          type="checkbox"
          class="portal__outline asset__list__list__item__checkbox"
          :checked="isInBasket"
          @change="toggleBasket()"
        />
      </label>

      <!-- Image -->
      <div
        class="asset__list__list__item__image"
        @click.prevent="openAssetDialog(item.id)"
      >
        <img :src="item.image.small" />
      </div>

      <!-- Extension -->
      <div
        class="badge bg-dark bg-opacity-25 asset__list__list__item__extension"
      >
        {{ item.extension }}
      </div>
    </div>

    <!-- Meta -->
    <div class="pt-2 asset__list__list__item__meta">
      <!-- Name -->
      <div class="portal__h6 mb-1 asset__list__list__item__name">
        {{ item.name }}
      </div>

      <div class="portal__font__xs asset__list__list__item__info">
        <!-- FileSize -->
        <div class="asset__list__list__item__size">
          {{ $filters.fileSize(item.fileSize, true) }}
        </div>
        <!--        Separator -->
        &middot;
        <!-- Date -->
        <div class="asset__list__list__item__date">
          {{ $filters.dateMed(item.createdAt) }}
        </div>
      </div>
      <!-- Actions -->
      <!--      <div class="asset__list__list__item__actions">-->
      <!--        <VDropdown :distance="6" placement="bottom-end">-->
      <!--          <button class="portal__button__icon">-->
      <!--            <span class="material-icons-outlined">more_vert</span>-->
      <!--          </button>-->
      <!--          <template #popper="{ hide }">-->
      <!--            <div class="portal__context__menu list-group-flush">-->
      <!--              <a-->
      <!--                :href="item.download"-->
      <!--                target="_blank"-->
      <!--                class="list-group-item portal__button__with__icon justify-content-start"-->
      <!--                @click="hide()"-->
      <!--              >-->
      <!--                <span class="material-icons-outlined">file_download</span>-->
      <!--                {{ $t("qualities.original") }}-->
      <!--              </a>-->
      <!--              <a-->
      <!--                :href="channelDownload.url"-->
      <!--                target="_blank"-->
      <!--                v-for="(channelDownload, index) in item.channelDownloads"-->
      <!--                :key="index"-->
      <!--                class="list-group-item portal__button__with__icon justify-content-start"-->
      <!--                @click="hide()"-->
      <!--              >-->
      <!--                <span class="material-icons-outlined">file_download</span>-->
      <!--                {{ channelDownload.channel_name }}-->
      <!--              </a>-->
      <!--            </div>-->
      <!--          </template>-->
      <!--        </VDropdown>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useBasketStore } from "@app/common/stores/basketStore.js";
import { openAssetDialog } from "@app/modules/asset-detail/helpers/openAssetDialog.js";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const basketStore = useBasketStore();
const isInBasket = computed(() => basketStore.isInBasket(props.item.id));

const toggleBasket = () => {
  if (isInBasket.value) {
    basketStore.removeFromBasket(props.item.id);
  } else {
    basketStore.addToBasket(props.item.id);
  }
};
</script>
