export default {
  brands: {
    selectBrand: "Marke auswählen",
  },
  messages: {
    noResults: "Keine Ergebnisse",
    noResultsFilter:
      "Keine Ergebnisse. Versuchen Sie es mit einer anderen Marke.",
  },
};
