<template>
  <div class="portal__dialog">
    <slot name="activator" :open="doOpen"></slot>
    <!--suppress HtmlUnknownTag -->
    <!-- https://v3.vue-final-modal.org/ -->
    <vue-final-modal
      v-model="isOpen"
      :classes="`portal__dialog__container${
        full ? ' portal__dialog__container--full' : ''
      }`"
      :content-class="`portal__dialog__content${
        full ? ' portal__dialog__content--full' : ''
      }`"
      overlay-class="portal__dialog__backdrop"
      v-bind="$attrs"
    >
      <slot :close="doClose"></slot>
    </vue-final-modal>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  full: {
    required: false,
    default: false,
    type: Boolean,
  },
});

const isOpen = ref(false);

const doOpen = () => {
  isOpen.value = true;
};

const doClose = () => {
  isOpen.value = false;
};

defineExpose({
  open: doOpen,
  close: doClose,
});
</script>

<style scoped>
/* TODO: Move to scss file */
::v-deep(.portal__dialog__backdrop) {
}
/* TODO: Responsive */
::v-deep(.portal__dialog__container) {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 1rem;
}
::v-deep(.portal__dialog__container--full) {
  padding: 0;
  width: 100%;
  height: 100%;
}
::v-deep(.portal__dialog__content) {
  margin: auto;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 400px;
  max-width: 400px;
}
::v-deep(.portal__dialog__content--full) {
  margin: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  border: none;
}
</style>
