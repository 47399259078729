<template>
  <footer class="portal__app__footer">
    <div class="my-4 app__footer">
      <ul class="portal__list__default app__footer__list">
        <li class="app__footer__list__item">
          <a
            :href="appInfo.links.imprint"
            class="portal__link fs-6 app__footer__list__item__link"
          >
            {{ $t("modules.appLayout.footer.imprint") }}
          </a>
        </li>
        <li class="app__footer__list__item">
          <a
            :href="appInfo.links.privacyPolicy"
            class="portal__link fs-6 app__footer__list__item__link"
          >
            {{ $t("modules.appLayout.footer.privacyPolicy") }}
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup>
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfo = useAppInfoStore();
</script>
