<template>
  <div class="portal__form">
    <portal-tree-view-node
      v-for="treeNode in tree"
      :key="treeNode.id"
      :node="treeNode"
      class="portal__treeview"
    />
  </div>
</template>

<script setup>
import PortalTreeViewNode from "@app/common/components/PortalTreeViewNode.vue";
import { provide } from "vue";

const props = defineProps({
  tree: {
    required: true,
    type: Array,
  },
  selectedCategories: {
    required: true,
    type: Array,
  },
  openCategories: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits([
  "update:selectedCategories",
  "update:openCategories",
]);

provide("isSelected", (node) => props.selectedCategories.includes(node.id));
provide("toggleSelection", (node, toggle) => {
  if (toggle === true) {
    emit("update:selectedCategories", [...props.selectedCategories, node.id]);
  } else {
    emit(
      "update:selectedCategories",
      props.selectedCategories.filter((n) => n !== node.id)
    );
  }
});
provide("isOpen", (node) => props.openCategories.includes(node.id));
provide("toggleOpen", (node, toggle) => {
  if (toggle === true) {
    emit("update:openCategories", [...props.openCategories, node.id]);
  } else {
    emit(
      "update:openCategories",
      props.openCategories.filter((n) => n !== node.id)
    );
  }
});
provide("isIndeterminate", (node) => {
  return isAnyChildActive(node);
});

function isAnyChildActive(node) {
  if (!node.children || !node.children.length) {
    return false;
  }
  if (
    node.children.find((child) => props.selectedCategories.includes(child.id))
  ) {
    return true;
  }
  if (node.children.find((child) => isAnyChildActive(child))) {
    return true;
  }
}
</script>
