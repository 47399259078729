import axios from "axios";
import router from "@app/router";
import { getLocaleForBackend } from "@app/common/plugins/i18n.js";
import { useUserInfoStore } from "presse-portal-frontend/src/common/stores/userInfoStore";

function setContentTypeIfUnset(headers, value) {
  if (
    typeof headers !== "undefined" &&
    typeof headers["Content-Type"] === "undefined"
  ) {
    headers["Content-Type"] = value;
  }
}

const localeInterceptor = (config) => {
  config.headers["Accept-Language"] = getLocaleForBackend();
  return config;
};

// Without auth
const axiosWithoutAuth = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      if (typeof data === "object") {
        setContentTypeIfUnset(headers, "application/json;charset=utf-8");
        return JSON.stringify(data, function (k, v) {
          return v === undefined ? null : v;
        });
      }
    },
  ],
});

axiosWithoutAuth.interceptors.request.use(localeInterceptor);

// With auth
const axiosWithAuth = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      headers["Authorization"] = useUserInfoStore().token;

      if (typeof data === "object") {
        setContentTypeIfUnset(headers, "application/json;charset=utf-8");
        return JSON.stringify(data, function (k, v) {
          return v === undefined ? null : v;
        });
      }
    },
  ],
});

axiosWithAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (router.currentRoute.name !== "login") {
        return router.push({ name: "login" });
      }
      return;
    }
    return Promise.reject(error);
  }
);

axiosWithAuth.interceptors.request.use(localeInterceptor);

export { axiosWithoutAuth, axiosWithAuth };
