export default {
  login: {
    fields: {
      email: "E-Mail",
      password: "Passwort",
    },
    actions: {
      forgotPassword: "Passwort vergessen",
      submit: "Login",
      register: "Registrieren",
    },
  },
  registration: {
    title: "Neuen Account erstellen",
    fields: {
      name: "Name",
      company: "Firma",
      department: "Ressort",
      position: "Position",
      email: "E-Mail",
      comment: "Kommentar",
    },
    texts: {
      termsV1: "Ich habe die",
      termsV2: "Datenschutzerklärung",
      termsV3: "gelesen und stimme dieser zu.",
      userData:
        "Ich bin damit einverstanden, das meine Daten zur Nutzung des Presseportals gespeichert werden.",
    },
    actions: {
      submit: "Account erstellen",
      cancel: "Abbrechen",
    },
    success: {
      title: "Registrierung erfolgreich",
      text: "Ihre Registrierung war erfolgreich. Sobald wir Ihren Zugang aktiviert haben, erhalten Sie eine Bestätigungsmail.",
      actions: {
        login: "Zum Login",
      },
    },
  },
  resetPassword: {
    title: "Passwort zurücksetzen",
    text: "TBD: Text",
    fields: {
      email: "E-Mail",
    },
    actions: {
      submit: "Neues Passwort anfordern",
      cancel: "Abbrechen",
    },
  },
  newPassword: {
    title: "Passwort zurücksetzen",
    text: "TBD: Text",
    fields: {
      password: "Neues Passwort",
    },
    actions: {
      submit: "Neues Passwort speichern",
      cancel: "Abbrechen",
    },
  },
};
