export default {
  navigation: {
    home: "Home",
    assetList: "Media",
    collections: "Collections",
  },
  languageSelector: {
    dialogTitle: "Switch language",
  },
  logout: {
    dialogTitle: "Logout",
    dialogQuestion: "Dou you really want to log out?",
    actions: {
      logout: "Logout",
      cancel: "Cancel",
    },
  },
  footer: {
    imprint: "Imprint",
    privacyPolicy: "Privacy policy",
  },
};
