<template>
  <!-- Page -->
  <div
    class="portal__auth__background"
    :style="style"
    :class="hasBackgroundImage ? 'portal__auth__background--image' : ''"
  />
  <div class="portal__auth">
    <!-- Header -->
    <header class="container portal__auth__header">
      <auth-layout-language-selector />
    </header>

    <!-- Content -->
    <div class="portal__auth__content">
      <slot />
    </div>

    <!-- Footer -->
    <auth-layout-footer />
  </div>
</template>

<script setup>
import AuthLayoutFooter from "@app/layouts/auth/AuthLayoutFooter.vue";
import AuthLayoutLanguageSelector from "@app/layouts/auth/AuthLayoutLanguageSelector.vue";
import { computed } from "vue";
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";

const appInfo = useAppInfoStore();

const style = computed(() => {
  return appInfo.branding.background
    ? {
        backgroundImage: `url(${appInfo.branding.background})`,
      }
    : {};
});

const hasBackgroundImage = computed(() => !!appInfo.branding.background);
</script>
