<template>
  <app-layout>
    <!-- Loader -->
    <transition name="fade" mode="out-in">
      <page-loader v-if="isLoading" />

      <!-- Page -->
      <div v-else>
        <!-- Header -->
        <dashboard-page-header :banner="banner" />

        <!-- Contact -->
        <div class="container portal__container__spacing__default">
          <dashboard-page-contact :contact="contact" />
        </div>
        <!-- Highlights -->
        <div class="container portal__container__spacing__default">
          <dashboard-page-highlights
            :highlights="highlights"
            :use-accordion="!highlightsNoAccordion"
          />
        </div>
      </div>
    </transition>
  </app-layout>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getDashboardData } from "@app/modules/dashboard/api/dashboardApi.js";
import DashboardPageHeader from "@app/modules/dashboard/pages/dashboard/DashboardPageHeader.vue";
import DashboardPageContact from "@app/modules/dashboard/pages/dashboard/DashboardPageContact.vue";
import DashboardPageHighlights from "@app/modules/dashboard/pages/dashboard/DashboardPageHighlights.vue";
import AppLayout from "@app/layouts/app/AppLayout.vue";
import PageLoader from "@app/common/components/PageLoader.vue";

const isLoading = ref(true);
const banner = ref(null);
const contact = ref(null);
const highlights = ref(null);
const highlightsNoAccordion = ref(null);

onMounted(async () => {
  const data = await getDashboardData();
  banner.value = data.banner;
  contact.value = data.contact;
  highlights.value = data.highlights;
  highlightsNoAccordion.value = data.highlightsNoAccordion || false;
  isLoading.value = false;
});
</script>
