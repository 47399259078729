<template>
  <div class="portal__form basket__quality__selection">
    <label for="qualitySelection">Qualität</label>
    <select
      id="qualitySelection"
      class="portal__form__select basket__quality__selection__select"
      v-model="basketStore.selectedChannel"
    >
      <option selected value="">Original</option>
      <option
        v-for="channel in appInfoStore.channels"
        :value="channel.code"
        :key="channel.code"
      >
        {{ channel.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { useAppInfoStore } from "@app/common/stores/appInfoStore.js";
import { useBasketStore } from "@app/common/stores/basketStore";

const appInfoStore = useAppInfoStore();
const basketStore = useBasketStore();
</script>
